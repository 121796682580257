import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getSelectedOrder = createAsyncThunk(
  'order/getSelectedOrder',
  async (selectedOrderID, { rejectWithValue }) => {
    // try {
      const response = await axios.get(`/order/detail/${selectedOrderID}`);
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);