import { createSlice } from '@reduxjs/toolkit';
import { putCourier } from './putCourier.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const putCourierSlice = createSlice({
  name: 'putCourier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putCourier.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(putCourier.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(putCourier.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default putCourierSlice.reducer;