import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const setPerformanceReportFilter = createAsyncThunk(
  'performanceReportFilter/setPerformanceReportFilter',
  async ({selectedBranchID, startDate, endDate}, { rejectWithValue }) => {
    // try {
      const response = await axios.post(`/report/performance/${selectedBranchID}`, { startDate, endDate });
      return response;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);