import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: [],
  defaultId: 'default',
  opened: false
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    menuOpen: (state, action) => {
      state.isOpen = [action.payload.id];
    },
    setMenu: (state, action) => {
      state.opened = action.payload.opened;
    },
  },
});

export const { menuOpen, setMenu } = customizationSlice.actions;

export default customizationSlice.reducer;