import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEarningsVehicle = createAsyncThunk(
  "earning/getEarningsVehicle",
  async ({ startDate, endDate, projectID }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/claim/claim-for-project`, {
        params: { projectID, startDate, endDate },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
