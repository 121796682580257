import { Layout } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
//
import { setMenu } from '../../core/store/customization/customization.slice';
import { getUserProfile } from '../../core/store/profile/getUser.repository';
import Header from './Header';
import SiderMenu from './Sidebar';
// import Breadcrumb from 'components/AppBreadcrumb';

const { Content } = Layout;

const MainLayout = () => {
    const location = useLocation();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const role = useSelector((state) => state.setRole?.data?.data?.scope);
    const user = useSelector((state) => state.getUser?.data);
    const dispatch = useDispatch();

    const handleLeftDrawerToggle = () => {
        dispatch(setMenu({ opened: !leftDrawerOpened }));
    };

    useEffect(() => {
        dispatch(setMenu({ opened: false }));
    }, [location, dispatch]);

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <CustomSider
                collapsible={false}
                collapsed={!leftDrawerOpened}
                onCollapse={handleLeftDrawerToggle}
                collapsedWidth={80}
            >
                <SiderMenu />
            </CustomSider>
            <Layout>
                <Header
                    handleLeftDrawerToggle={handleLeftDrawerToggle}
                    collapsed={!leftDrawerOpened}
                    role={role}
                    user={user}
                />
                <Content style={{ margin: '12px 8px' }}>
                    {/* <Breadcrumb /> */}
                    <div style={{ padding:12, minHeight: 360 }}>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};


const CustomSider = styled(Layout.Sider)`
  background: #ffffff !important;
`;

export default MainLayout;