import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const deleteClient = createAsyncThunk(
  "deleteClientManager",
  async (email, { rejectWithValue }) => {
    const response = await axios.delete(`/user/${email}/clientAdminRole`);
    return response.data;
  }
);
