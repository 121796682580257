import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putApproveBill = createAsyncThunk(
  "bill/putApproveBill",
  async (claimID, { rejectWithValue }) => {    
      const response = await axios.put(`/claim/accountant-invoice-approve/${claimID}`);
      return response.data;
  }
);
