import React from "react";
import Routes from "./core/utils/routes";
import "./App.css"

function App() {
  // const breakTheApp = () => {
  //   throw new Error("First error!");
  // };

  return (
      <Routes />
  );
}

export default App;
