import { combineReducers } from "@reduxjs/toolkit";
//
import authReducer from "./auth/auth.slice";
import setRoleReducer from "./auth/setRole.slice";
import customizationReducer from "./customization/customization.slice";
import getUserReducer from "./profile/getUser.slice";
import getOrderReducer from "./order/getOrder.slice";
//
import getRouteBranchReducer from "./route/getRouteBranch.slice";
import getRouteOrderReducer from "./route/getRouteOrder.slice";
import getRouteReducer from "./route/getRoute.slice";
import getRouteDetailsReducer from "./route/getRouteDetails.slice";
import setRouteReducer from "./route/setRoute.slice";
import putRouteApproveReducer from "./route/putRouteApprove.slice";
import putRouteVehicleReducer from "./route/putRouteVehicle.slice";
import putDirectApproveReducer from "./route/putDirectApprove.slice";
//
import getProjectReducer from "./project/getProject.slice";
import getProvincesReducer from "./address/getProvinces.slice";
//
import setExcelJsonReducer from "./order/setExcelJson.slice";
import setAddressToCoordinateReducer from "./order/setAddressToCoordinate.slice";
import setOrderCordToAddressReducer from "./order/setOrderCordToAddress.slice";
import setOrderFormReducer from "./order/setOrderForm.slice";
import getSelectedOrderReducer from "./order/getSelectedOrder.slice";
import putChangeOrderLocationReducer from "./order/putChangeOrderLocation.slice";
//
import getBranchReducer from "./branch/getBranch.slice";
import getSubcontractorReducer from "./subcontractor/subcontractor/getSubcontractor.slice";
import getSubcontractorIdReducer from "./subcontractor/subcontractor/getSubcontractorId.slice";
import putSubcontractorReducer from "./subcontractor/subcontractor/putSubcontractor.slice";
import deleteSubcontractorReducer from "./subcontractor/subcontractor/deleteSubcontractor.slice";
import setSubcontractorReducer from "./subcontractor/subcontractor/setSubcontractor.slice";
import setProjectReducer from "./project/setProject.slice";
import setBranchReducer from "./branch/setBranch.slice";
import putProjectReducer from "./project/putProject.slice";
import deleteProjectReducer from "./project/deleteProject.slice";
import getProjectIdReducer from "./project/getProjectId.slice";
import deleteBranchReducer from "./branch/deleteBranch.slice";
import getBranchIdReducer from "./branch/getBranchId.slice";
import getEnumsReducer from "./enums/getEnums.slice";
import setTimeSlotsReducer from "./route/setTimeSlots.slice";
//
import setClientManagerReducer from "./user/client-manager/setClientManager.slice";
import putClientManagerReducer from "./user/client-manager/putClientManager.slice";
import deleteClientManagerReducer from "./user/client-manager/deleteClientManager.slice";
import getClientManagerReducer from "./user/client-manager/getClientManager.slice";
import getClientManagerListReducer from "./user/client-manager/getClientManagerList.slice";
//
import setClientOperationsReducer from "./user/client-operations/setClientOperations.slice";
import putClientOperationsReducer from "./user/client-operations/putClientOperations.slice";
import deleteClientOperationsReducer from "./user/client-operations/deleteClientOperations.slice";
import getClientOperationsReducer from "./user/client-operations/getClientOperations.slice";
import getClientOperationsListReducer from "./user/client-operations/getClientOperationsList.slice";
//
import setCourierReducer from "./user/courier/setCourier.slice";
import putCourierReducer from "./user/courier/putCourier.slice";
import deleteCourierReducer from "./user/courier/deleteCourier.slice";
import getCourierReducer from "./user/courier/getCourier.slice";
import getCourierListReducer from "./user/courier/getCourierList.slice";
import getCourieApprovelListReducer from "./user/approve-courier/getCourierApprovelList.slice";
//
import setOperationsReducer from "./user/operations/setOperations.slice";
import putOperationsReducer from "./user/operations/putOperations.slice";
import deleteOperationsReducer from "./user/operations/deleteOperations.slice";
import getOperationsReducer from "./user/operations/getOperations.slice";
import getOperationsListReducer from "./user/operations/getOperationsList.slice";
//
import setSupervisorReducer from "./user/supervisor/setSupervisor.slice";
import putSupervisorReducer from "./user/supervisor/putSupervisor.slice";
import deleteSupervisorReducer from "./user/supervisor/deleteSupervisor.slice";
import getSupervisorReducer from "./user/supervisor/getSupervisor.slice";
import getSupervisorListReducer from "./user/supervisor/getSupervisorList.slice";
//
import setVehicleReducer from "./user/vehicle/setVehicle.slice";
import getVehicleReducer from "./user/vehicle/getVehicle.slice";
import getVehicleListReducer from "./user/vehicle/getVehicleList.slice";
import putVehicleReducer from "./user/vehicle/putVehicle.slice";
import deleteVehicleReducer from "./user/vehicle/deleteVehicle.slice";
import getRouteVehicleReducer from "./route/getRouteVehicle.slice";
import deleteOrderReducer from "./order/deleteOrder.slice";
import cancelOrderReducer from "./order/cancelOrder.slice";
//
import patchrouteEndReducer from "./routeEnd/patchRouteEnd.slice";
//
import getUploadFileReducer from "./uploadFile/getUploadFile.slice";
import setUploadFileReducer from "./uploadFile/setUploadFile.slice";
//
import setEmailReducer from "./email/setEmail.slice";
import setOrderTransferReducer from "./route/setOrderTransfer.slice";
//
import getNotificationReducer from "./notification/getNotification.slice";
import deleteNotificationReducer from "./notification/deleteNotification.slice";
import readNotificationReducer from "./notification/readNotification.slice";
import unreadNotificationReducer from "./notification/unreadNotification.slice";
//
import setDashboardFilterReducer from "./dashboard/setDashboardFilter.slice";
import setDashboardDelayedFilterReducer from "./dashboard/setDashboardDelayedFilter.slice";
import setDashboardRiskyFilterReducer from "./dashboard/setDashboardRiskyFilter.slice";
import setDashboardUnroutedFilterReducer from "./dashboard/setDashboardUnroutedFilter.slice";
import setDashboardTotalOrdersFilterReducer from "./dashboard/setDashboardTotalOrdersFilter.slice";
import setDashboardCanceledOrdersFilterReducer from "./dashboard/setDashboardCanceledOrdersFilter.slice";
import setDashboardDeliveredOnTimeOrdersFilterReducer from "./dashboard/setDashboardDeliveredOnTimeOrdersFilter.slice";
import setDashboardDeliveredOrdersFilterReducer from "./dashboard/setDashboardDeliveredOrdersFilter.slice";
import setDashboardInDistributionOrdersFilterReducer from "./dashboard/setDashboardInDistributionOrdersFilter.slice";
import setDashboardPickedOrdersFilterReducer from "./dashboard/setDashboardPickedOrdersFilter.slice";
import setDashboardUndeliveredOrdersFilterReducer from "./dashboard/setDashboardUndeliveredOrdersFilter.slice";
//
import setDebitFilterReducer from "./debit/setDebitFilter.slice";
import setDebitVehicleFilterReducer from "./debit/setDebitVehicleFilter.slice";
import patchDebitDismissReducer from "./debit/patchDebitDismiss.slice";
//
import setDebitReportFilterReducer from "./report/setDebitReportFilter.slice";
import setDistributorReportFilterReducer from "./report/setDistributorReportFilter.slice";
import setOrderReportFilterReduer from "./report/setOrderReportFilter.slice";
import setPerformanceReportFilterReducer from "./report/setPerformanceReportFilter.slice";
import setRouteReportFilterReducer from "./report/setRouteReportFilter.slice";
import getSelectedProjectsReducer from "./project/getSelectedProjects.slice";
import putRouteDisapproveReducer from "./route/putRoutDisapprove.slice";
import putRoutePriceReducer from "./route/putRoutePrice.slice";
import getPlannerOrderReducer from "./order/getPlannerOrder.slice";
import getPlannerOrderCountsReducer from "./order/getPlannerOrderCounts.slice";
import getLocationsReducer from "./location/getLocations.slice";
import getLastLocationReducer from "./location/getLastLocation.slice";
import getRouteByDateReducer from "./route/getRouteByDate.slice";
//
import setEarningReducer from "./earning/setEarning.slice";
import getEarningReducer from "./earning/getEarning.slice";
import deleteEarningReducer from "./earning/deleteEarning.slice";
import getEarningDetailReducer from "./earning/getEarningDetail.slice";
import putEarningReducer from "./earning/putEarning.slice";
import getEarningsWithProjectsReducer from "./earning/getEarningsWithProjects.slice";
import getEarningsVehicleReducer from "./earning/getEarningVehicle.slice";
import putApproveBillReducer from "./earning/putApproveBill.slice";
import putRejectBillReducer from "./earning/putRejectBill.slice";
import getCategoryListReducer from "./earning/getCategoryList.slice";
//
import putApproveUserReducer from "./user/approve-user/putApproveUser.slice";
import putRejectUserReducer from "./user/reject-user/putRejectUser.slice";
import getVehicleApprovelListReducer from "./user/approve-vehicle/getVehicleApprovelList.slice";
import putCourierApprovelReducer from "./user/approve-courier/putCourierApprovel.slice";
import putVehicleApprovelReducer from "./user/approve-vehicle/putVehicleApprovel.slice";
import putCourierVehicleAssignmentReducer from "./user/courier/putCourierVehicleAssignment.slice";
import putPaidBillReducer from "./earning/putPaidBill.slice";
import getPaymentWaitingEarningsReducer from "./earning/getPaymentWaitingEarnings.slice";
import getAccountantListReducer from "./user/accountant/getAccountantList.slice";
import setAccountantReducer from "./user/accountant/setAccountant.slice";
import getAccountantReducer from "./user/accountant/getAccountant.slice";
import putAccountantReducer from "./user/accountant/putAccountant.slice";
import deleteAccountantReducer from "./user/accountant/deleteAccountant.slice";
import getSubcontractorPendingListReducer from "./subcontractor/subcontractorPending/getSubcontractorPendingList.slice";
import putApproveSubcontractorReducer from "./subcontractor/subcontractorApproveReject/putApproveSubcontractor.slice";
import putRejectSubcontractorReducer from "./subcontractor/subcontractorApproveReject/putRejectSubcontractor.slice";
import putUpdateAccountingIDReducer from "./subcontractor/subcontractorApproveReject/putUpdateAccountingID.slice";
import putApproveSubcontractorAndVehicleReducer from "./user/operation-vehicle-reject-approve/putApproveSubcontractorAndVehicle.slice";
import putRejectSubcontractorAndVehicleReducer from "./user/operation-vehicle-reject-approve/putRejectSubcontractorAndVehicle.slice";
import setSubcontractorAndVehicleReducer from "./user/subcontractorAndVehicle/setSubcontractorAndVehicle.slice";
//
import getClientListReducer from "./user/client-admin/getClientList.slice";
import deleteClientReducer from "./user/client-admin/deleteClient.slice";
import getClientReducer from "./user/client-admin/getClient.slice";
import putClientReducer from "./user/client-admin/putClient.slice";
import setClientReducer from "./user/client-admin/setClient.slice";
import putBranchReducer from "./branch/putBranch.slice";

const rootReducer = combineReducers({
  //subcontractor
  getSubcontractor: getSubcontractorReducer,
  getSubcontractorPendingList: getSubcontractorPendingListReducer,
  getSubcontractorId: getSubcontractorIdReducer,
  setSubcontractor: setSubcontractorReducer,
  putSubcontractor: putSubcontractorReducer,
  deleteSubcontractor: deleteSubcontractorReducer,
  putApproveSubcontractor: putApproveSubcontractorReducer,
  putRejectSubcontractor: putRejectSubcontractorReducer,
  putUpdateAccounting: putUpdateAccountingIDReducer,
  // auth
  auth: authReducer,
  setRole: setRoleReducer,
  // app
  customization: customizationReducer,
  //branch
  getBranch: getBranchReducer,
  setBranch: setBranchReducer,
  deleteBranch: deleteBranchReducer,
  getBranchId: getBranchIdReducer,
  putBranch: putBranchReducer,
  //debit
  setDebitFilter: setDebitFilterReducer,
  setDebitVehicleFilter: setDebitVehicleFilterReducer,
  patchDebitDismiss: patchDebitDismissReducer,
  //dashboard
  setDashboardFilter: setDashboardFilterReducer,
  setDashboardDelayedFilter: setDashboardDelayedFilterReducer,
  setDashboardRiskyFilter: setDashboardRiskyFilterReducer,
  setDashboardUnroutedFilter: setDashboardUnroutedFilterReducer,
  setDashboardTotalOrdersFilter: setDashboardTotalOrdersFilterReducer,
  setDashboardCanceledOrdersFilter: setDashboardCanceledOrdersFilterReducer,
  setDashboardOnTimeOrdersFilter:
    setDashboardDeliveredOnTimeOrdersFilterReducer,
  setDashboardDeliveredOrdersFilter: setDashboardDeliveredOrdersFilterReducer,
  setDashboardDistributionOrdersFilter:
    setDashboardInDistributionOrdersFilterReducer,
  setDashboardPickedOrdersFilter: setDashboardPickedOrdersFilterReducer,
  setDashboardUndeliveredOrdersFilter:
    setDashboardUndeliveredOrdersFilterReducer,
  // client-manager
  //earning
  setEarning: setEarningReducer,
  getEarning: getEarningReducer,
  getEarningDetail: getEarningDetailReducer,
  deleteEarning: deleteEarningReducer,
  putEarning: putEarningReducer,
  getEarningWithProjects: getEarningsWithProjectsReducer,
  getEarningsVehicle: getEarningsVehicleReducer,
  putApproveBill: putApproveBillReducer,
  putRejectBill: putRejectBillReducer,
  putPaidBill: putPaidBillReducer,
  getPaymentWaitingEarnings: getPaymentWaitingEarningsReducer,
  getCategoryList: getCategoryListReducer,
  //enums
  getEnums: getEnumsReducer,
  //email
  setEmail: setEmailReducer,
  //location
  getLocations: getLocationsReducer,
  getLastLocation: getLastLocationReducer,
  //notification
  getNotification: getNotificationReducer,
  deleteNotification: deleteNotificationReducer,
  readNotification: readNotificationReducer,
  unreadNotification: unreadNotificationReducer,
  // order
  getOrder: getOrderReducer,
  setExcelJson: setExcelJsonReducer,
  setAddressToCoordinate: setAddressToCoordinateReducer,
  setOrderCordToAddress: setOrderCordToAddressReducer,
  setOrderForm: setOrderFormReducer,
  deleteOrder: deleteOrderReducer,
  getSelectedOrder: getSelectedOrderReducer,
  putChangeOrderLocation: putChangeOrderLocationReducer,
  getPlannerOrder: getPlannerOrderReducer,
  getPlannerOrderCounts: getPlannerOrderCountsReducer,
  cancelOrderReducer: cancelOrderReducer,
  // project
  getSelectedProjects: getSelectedProjectsReducer,
  //report
  setDebitReportFilter: setDebitReportFilterReducer,
  setDistributorReportFilter: setDistributorReportFilterReducer,
  setOrderReportFilter: setOrderReportFilterReduer,
  setPerformanceReportFilter: setPerformanceReportFilterReducer,
  setRouteReportFilter: setRouteReportFilterReducer,
  // route
  getRoute: getRouteReducer,
  getRouteDetail: getRouteDetailsReducer,
  getRouteBranch: getRouteBranchReducer,
  getRouteOrder: getRouteOrderReducer,
  selectedTimeSlots: setTimeSlotsReducer,
  setRoute: setRouteReducer,
  putRouteApprove: putRouteApproveReducer,
  putRouteDispprove: putRouteDisapproveReducer,
  getRouteVehicle: getRouteVehicleReducer,
  putRouteVehicle: putRouteVehicleReducer,
  patchrouteEnd: patchrouteEndReducer,
  setOrderTransfer: setOrderTransferReducer,
  putRoutePrice: putRoutePriceReducer,
  getRouteByDate: getRouteByDateReducer,
  putDirectApprove: putDirectApproveReducer,
  //user-supervisor
  //project
  getProjectList: getProjectReducer,
  setProject: setProjectReducer,
  putProject: putProjectReducer,
  deleteProjec: deleteProjectReducer,
  getProjectId: getProjectIdReducer,
  //provinces
  getProvinces: getProvincesReducer,
  // user
  getUser: getUserReducer,
  putApproveUser: putApproveUserReducer,
  putRejectUser: putRejectUserReducer,
  setSubcontractorAndVehicle: setSubcontractorAndVehicleReducer,
  //user-client-admin
  getClientList: getClientListReducer,
  getClient: getClientReducer,
  deleteClient: deleteClientReducer,
  putClient: putClientReducer,
  setClient: setClientReducer,
  //user-client-manager
  getClientManagerList: getClientManagerListReducer,
  getClientManager: getClientManagerReducer,
  setClientManager: setClientManagerReducer,
  deleteClientManager: deleteClientManagerReducer,
  putClientManager: putClientManagerReducer,
  //user-client-operations
  getClientOperationsList: getClientOperationsListReducer,
  getClientOperations: getClientOperationsReducer,
  deleteClientOperations: deleteClientOperationsReducer,
  putClientOperations: putClientOperationsReducer,
  setClientOperations: setClientOperationsReducer,
  //user-accountant
  getAccountantList: getAccountantListReducer,
  setAccountant: setAccountantReducer,
  getAccountant: getAccountantReducer,
  putAccountant: putAccountantReducer,
  deleteAccountant: deleteAccountantReducer,
  //user-courier
  getCourierList: getCourierListReducer,
  getCourierApprovelList: getCourieApprovelListReducer,
  getCourier: getCourierReducer,
  setCourier: setCourierReducer,
  deleteCourier: deleteCourierReducer,
  putCourier: putCourierReducer,
  putCourierApprovel: putCourierApprovelReducer,
  putCourierVehicleAssign: putCourierVehicleAssignmentReducer,
  //user-operations
  getOperations: getOperationsReducer,
  getOperationsList: getOperationsListReducer,
  setOperations: setOperationsReducer,
  deleteOperations: deleteOperationsReducer,
  putOperations: putOperationsReducer,
  //user-supervisor
  setSupervisor: setSupervisorReducer,
  getSupervisorList: getSupervisorListReducer,
  getSupervisor: getSupervisorReducer,
  deleteSupervisor: deleteSupervisorReducer,
  putSupervisor: putSupervisorReducer,
  //user-vehicle
  setVehicle: setVehicleReducer,
  getVehicleList: getVehicleListReducer,
  getVehicleApprovelList: getVehicleApprovelListReducer,
  getVehicle: getVehicleReducer,
  putVehicle: putVehicleReducer,
  deleteVehicle: deleteVehicleReducer,
  putVehicleApprovel: putVehicleApprovelReducer,
  putApproveSubcontractorAndVehicle: putApproveSubcontractorAndVehicleReducer,
  putRejectSubcontractorAndVehicle: putRejectSubcontractorAndVehicleReducer,
  // upload-file
  getUploadFile: getUploadFileReducer,
  setUploadFile: setUploadFileReducer,
});

export default rootReducer;
