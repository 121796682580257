import { ConfigProvider } from "antd";
import trTr from "antd/lib/locale/tr_TR";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persister } from "./core/store/store";
import { LoadScript } from "@react-google-maps/api";
import * as Sentry from "@sentry/react";
//
import App from "./App";
import { ThemeContext } from "./core/utils/contexts/themeContext";
import { colors } from "./core/utils/helpers/theme";
import Loader from "./components/Loader/Loader";

const libraries = ["places", "drawing", "geometry"];

Sentry.init({
  dsn: "https://e09c462d1aa28be10fba51d57436fee7@o4506325719973888.ingest.us.sentry.io/4508048731078656",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  profilesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persister}>
      <BrowserRouter>
        <ConfigProvider locale={trTr}>
          <ThemeContext.Provider value={colors}>
            <ThemeProvider theme={colors}>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={libraries}
                loadingElement={<Loader />}
              >
                  <App />
              </LoadScript>
            </ThemeProvider>
          </ThemeContext.Provider>
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
