import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSubcontractorPendingList = createAsyncThunk(
  "getSubcontractorPendingList",
  async ( _ , { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/subcontractor/pending-approve-list`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
