import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putBranch = createAsyncThunk(
  "putBranch",
  async ({ branchID, requestBody }, { rejectWithValue }) => {
    const response = await axios.put(`/branch/${branchID}`, requestBody);
    return response.data;
  }
);
