import { createSlice } from '@reduxjs/toolkit';
import { putApproveUser } from './putApproveUser.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const putApproveUserSlice = createSlice({
  name: 'putApproveUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putApproveUser.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(putApproveUser.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(putApproveUser.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default putApproveUserSlice.reducer;