import { createSlice } from "@reduxjs/toolkit";
import { setSubcontractorAndVehicle } from "./setSubcontractorAndVehicle.repository";
const initialState = {
  loading: "idle",
  data: null,
  error: null,
};

const setSubcontractorAndVehicleSlice = createSlice({
  name: "setSubcontractorAndVehicle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSubcontractorAndVehicle.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(setSubcontractorAndVehicle.fulfilled, (state, action) => {
        state.loading = "idle";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(setSubcontractorAndVehicle.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.payload;
      });
  },
});

export default setSubcontractorAndVehicleSlice.reducer;
