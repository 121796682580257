import { createSlice } from '@reduxjs/toolkit';
import { setDistributorReportFilter } from './setDistributorReportFilter.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const setDistributorReportFilterSlice = createSlice({
  name: 'setDistributorReportFilter',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setDistributorReportFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDistributorReportFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(setDistributorReportFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { clearData } = setDistributorReportFilterSlice.actions;
export default setDistributorReportFilterSlice.reducer;