import React, { lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
//
import Loadable from "../../../components/Loader/Loadable";
import MainLayout from "./../../../layout/MainLayout/index";
import { signInWithToken } from "../../../firebase";
import { listenToCollectionChanges } from "../../../firebase";
//
const ProfileScreen = Loadable(
  lazy(() => import("../../../screens/App/Profile"))
);
//OPERATION
const OrderManagement = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationManagement/OrderManagement")
  )
);
const OperationDashboardScreen = Loadable(
  lazy(() => import("../../../screens/App/Operation/OperationDashboard"))
);
const OperationDashboardDetailScreen = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationDashboard/Details")
  )
);
const OperationPlannerScreen = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationPlanner/PlannerScreen")
  )
);
const OperationPlannerRoutesScreen = Loadable(
  lazy(() =>
    import(
      "../../../screens/App/Operation/OperationPlanner/PlannerRoutesScreen"
    )
  )
);
const OperationPlannerRouteDetailScreen = Loadable(
  lazy(() =>
    import(
      "../../../screens/App/Operation/OperationPlanner/PlannerRoutesScreen/PlannerRouteDetailScreen"
    )
  )
);
const OperationDebitReportPage = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationReport/DebitReport")
  )
);
const OperationOrderReportPage = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationReport/OrderReport")
  )
);
const OperationRouteReportPage = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationReport/RouteReport")
  )
);
const OperationDistributorReportPage = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationReport/DistributorReport")
  )
);
const OperationPerformanceReportPage = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationReport/PerformanceReport")
  )
);
const OrderDetailScreen = Loadable(
  lazy(() =>
    import(
      "../../../screens/App/Operation/OperationManagement/OrderManagement/OrderDetail"
    )
  )
);
const DebitManagement = Loadable(
  lazy(() =>
    import("../../../screens/App/Operation/OperationManagement/DebitManagement")
  )
);
const OperationCoordinateManagement = Loadable(
  lazy(() =>
    import(
      "../../../screens/App/Operation/OperationManagement/CoordinateManagement"
    )
  )
);
const OperationLiveTracking = Loadable(
  lazy(() => import("../../../screens/App/Operation/OperationLiveTracking"))
);
//SUPERVISOR
const SupervisorDashboard = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Dashboard/index"))
);
const ProjectManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/ProjectManagement"))
);
const BranchManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/BranchManagement"))
);
const BranchManagementDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/BranchManagement/DetailBranch")
  )
);
const Subcontractor = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Subcontractor"))
);
const Accountant = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Accountant"))
);
const ClientManagerManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/ClientManagement"))
);
const ClientOperationManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/ClientOperations"))
);
const CourierManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Courier"))
);
const OperationManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Operations"))
);
const SupervisorManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Supervisor"))
);
const VehicleManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Vehicle"))
);
const AccountantDetail = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Accountant/Details"))
);
const ClientManagement = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/ClientAdmin"))
);
const ClientManagementDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/Users/ClientAdmin/Details")
  )
);
const ClientManagerManagementDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/Users/ClientManagement/Details")
  )
);
const ClientOperationsManagementDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/Users/ClientOperations/Details")
  )
);
const CourierManagementDetail = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Courier/Details"))
);
const OperationManagementDetail = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Operations/Details"))
);
const SupervisorManagementDetail = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Supervisor/Details"))
);
const VehicleManagementDetail = Loadable(
  lazy(() => import("../../../screens/App/Supervisor/Users/Vehicle/Details"))
);
const SubcontractorDetail = Loadable(
  lazy(() =>
    import(
      "../../../screens/App/Supervisor/Users/Subcontractor/DetailSubcontractor"
    )
  )
);
const ProjectManagementDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/ProjectManagement/DetailProject")
  )
);
const CourierApprove = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/UserApprove/CourierApprove")
  )
);
const VehicleApprove = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/UserApprove/VehicleApprove")
  )
);
const VehicleApproveDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/UserApprove/VehicleApprove/Detail")
  )
);
const CourierApproveDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Supervisor/UserApprove/CourierApprove/Details")
  )
);
// ACCOUNTANT
const AccountantEarning = Loadable(
  lazy(() => import("../../../screens/App/Accountant/Earnings/index"))
);
const AccountantEarningManagement = Loadable(
  lazy(() =>
    import("../../../screens/App/Accountant/Earnings/EarningManagement")
  )
);
const AccountantEarningDetail = Loadable(
  lazy(() => import("../../../screens/App/Accountant/Earnings/EarningDetail"))
);

const AccountantEarningPaymentManagement = Loadable(
  lazy(() =>
    import("../../../screens/App/Accountant/Earnings/EarningPaymentManagement")
  )
);

const AccountantSubcontractorApprove = Loadable(
  lazy(() => import("../../../screens/App/Accountant/SubcontractorApprove"))
);

const AccountantSubcontractorApprovelDetail = Loadable(
  lazy(() =>
    import("../../../screens/App/Accountant/SubcontractorApprove/Details")
  )
);

const MainRoutes = () => {
  const role = useSelector((state) => state.setRole?.data?.data?.scope);
  const fbToken = useSelector((state) => state.setRole?.data?.data.fbToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const refresh = useSelector(
    (state) => state.setRole?.data?.data.refreshToken
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = listenToCollectionChanges(dispatch, isAuthenticated);
    return () => unsubscribe();
  }, [dispatch, isAuthenticated]);

  React.useEffect(() => {
    if (fbToken) {
      signInWithToken(fbToken, role, refresh, dispatch);
    }
  }, [fbToken, role, refresh, dispatch]);

  const operationRoutes = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <OperationDashboardScreen /> },
        { path: "operation-dashboard", element: <OperationDashboardScreen /> },
        {
          path: "operation-dashboard-detail",
          element: <OperationDashboardDetailScreen />,
        },
        { path: "operation-order-management", element: <OrderManagement /> },
        {
          path: "operation-order-management/detail/:id",
          element: <OrderDetailScreen />,
        },
        { path: "operation-planner/*", element: <OperationPlannerScreen /> },
        {
          path: "operation-planner-routes/*",
          element: <OperationPlannerRoutesScreen />,
        },
        {
          path: "operation-planner-routes/detail/:id",
          element: <OperationPlannerRouteDetailScreen />,
        },
        { path: "operation-courier-debit", element: <DebitManagement /> },
        { path: "operation-live-tracking", element: <OperationLiveTracking /> },
        {
          path: "operation-debit-report",
          element: <OperationDebitReportPage />,
        },
        {
          path: "operation-order-report",
          element: <OperationOrderReportPage />,
        },
        {
          path: "operation-route-report",
          element: <OperationRouteReportPage />,
        },
        {
          path: "operation-distributor-report",
          element: <OperationDistributorReportPage />,
        },
        {
          path: "operation-performance-report",
          element: <OperationPerformanceReportPage />,
        },
        {
          path: "operation-coordinate",
          element: <OperationCoordinateManagement />,
        },
        {
          path: "courier-management/detail/:id",
          element: <CourierManagementDetail />,
        },
        {
          path: "operation-courier-management",
          element: <CourierManagement />,
        },
        {
          path: "operation-vehicle-management",
          element: <VehicleManagement />,
        },
        {
          path: "vehicle-management/detail/:id",
          element: <VehicleManagementDetail />,
        },
        {
          path: "vehicle-approvel/detail/:id",
          element: <VehicleApproveDetail />,
        },
        { path: "subcontractor/detail/:id", element: <SubcontractorDetail /> },
        { path: "operation-subcontractor", element: <Subcontractor /> },
        { path: "operation-vehicle-approve", element: <VehicleApprove /> },
        { path: "operation-courier-approve", element: <CourierApprove /> },
        { path: "profile", element: <ProfileScreen /> },
        { path: "*", element: <Navigate to="/operation-dashboard" replace /> },
      ],
    },
  ];

  const supervisorRoutes = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <SupervisorDashboard /> },
        { path: "supervisor-dashboard", element: <SupervisorDashboard /> },
        {
          path: "supervisor-project-management",
          element: <ProjectManagement />,
        },
        { path: "supervisor-branch-management", element: <BranchManagement /> },
        {
          path: "supervisor-branch-management/detail/:id",
          element: <BranchManagementDetail />,
        },
        {
          path: "supervisor-accountant-management",
          element: <Accountant />,
        },
        {
          path: "supervisor-clientManager-management",
          element: <ClientManagerManagement />,
        },
        {
          path: "supervisor-clientOperation-management",
          element: <ClientOperationManagement />,
        },
        {
          path: "supervisor-courier-management",
          element: <CourierManagement />,
        },
        {
          path: "supervisor-operation-management",
          element: <OperationManagement />,
        },
        {
          path: "supervisor-supervisorUser-management",
          element: <SupervisorManagement />,
        },
        {
          path: "supervisor-vehicle-management",
          element: <VehicleManagement />,
        },
        {
          path: "accountant/detail/:id",
          element: <AccountantDetail />,
        },
        {
          path: "supervisor-client-management",
          element: <ClientManagement />,
        },
        {
          path: "supervisor-client-management/detail/:id",
          element: <ClientManagementDetail />,
        },
        {
          path: "clientManager-management/detail/:id",
          element: <ClientManagerManagementDetail />,
        },
        {
          path: "clientOperation-management/detail/:id",
          element: <ClientOperationsManagementDetail />,
        },
        {
          path: "courier-management/detail/:id",
          element: <CourierManagementDetail />,
        },
        {
          path: "courier-approvel/detail/:id",
          element: <CourierApproveDetail />,
        },
        {
          path: "operation-management/detail/:id",
          element: <OperationManagementDetail />,
        },
        {
          path: "supervisor-management/detail/:id",
          element: <SupervisorManagementDetail />,
        },
        {
          path: "vehicle-management/detail/:id",
          element: <VehicleManagementDetail />,
        },
        {
          path: "vehicle-approvel/detail/:id",
          element: <VehicleApproveDetail />,
        },
        { path: "supervisor-vehicle-approve", element: <VehicleApprove /> },
        { path: "supervisor-courier-approve", element: <CourierApprove /> },
        { path: "subcontractor/detail/:id", element: <SubcontractorDetail /> },
        {
          path: "supervisor-project-management/detail/:id",
          element: <ProjectManagementDetail />,
        },
        { path: "subcontractor", element: <Subcontractor /> },
        { path: "profile", element: <ProfileScreen /> },
        { path: "*", element: <Navigate to="/supervisor-dashboard" replace /> },
      ],
    },
  ];

  const accountantRoutes = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <AccountantEarning /> },
        {
          path: "accountant-earning-management",
          element: <AccountantEarningManagement />,
        },
        {
          path: "accountant-earning-payment-management",
          element: <AccountantEarningPaymentManagement />,
        },
        { path: "accountant-earning", element: <AccountantEarning /> },
        {
          path: "accountant-earning/detail/:id",
          element: <AccountantEarningDetail />,
        },
        {
          path: "accountant-subcontractor-approve",
          element: <AccountantSubcontractorApprove />,
        },
        {
          path: "subcontractor-approvel/detail/:id",
          element: <AccountantSubcontractorApprovelDetail />,
        },
        { path: "profile", element: <ProfileScreen /> },
        { path: "*", element: <Navigate to="/accountant-earning" replace /> },
      ],
    },
  ];

  const customerOperationRoutes = [
    {
      path: "/",
      element: <MainLayout />,
      children: [],
    },
  ];

  const customerManagerRoutes = [
    {
      path: "/",
      element: <MainLayout />,
      children: [],
    },
  ];

  const defaultRoute = [
    {
      path: "/",
      element: <MainLayout />,
      children: [],
    },
  ];

  let filteredRoutes;

  switch (role) {
    case "operations":
      filteredRoutes = operationRoutes;
      break;
    case "accountant":
      filteredRoutes = accountantRoutes;
      break;
    case "supervisor":
      filteredRoutes = supervisorRoutes;
      break;
    case "clientManager":
      filteredRoutes = customerManagerRoutes;
      break;
    case "clientOperations":
      filteredRoutes = customerOperationRoutes;
      break;
    default:
      filteredRoutes = defaultRoute;
  }

  return filteredRoutes;
};
export default MainRoutes;
