
export const TIME_SLOTS = [
  "0 - 30 dk",
  "30 - 60 dk",
  "1 - 1.5 saat",
  "1.5 - 2 saat",
  "2 - 2.5 saat",
  "2.5 - 3 saat",
  "3+ saat",
  "Geçmiş Siparişler",
  "Günü Geçmiş Siparişler",
  "Geniş Aralık",
];

export const roleTranslations = {
  supervisor: "YÖNETİCİ",
  operations: "OPERASYON",
  courier: "KURYE",
  clientOperations: "MÜŞTERİ OPERASYON",
  clientManager: "MÜŞTERİ YÖNETİCİ YÖNETİMİ",
  subcontractor: "TAŞERON",
  vehicle: "ARAÇ",
  accountant: "MUHASEBE",
  accountantRole: "MUHASEBE",
};

export const orderValuesExcel = {
  selectedProject: "",
  selectedCity: "",
  selectedDistrict: "",
  selectedBranch: "",
};

export const orderExcelKeyMap = {
  "REFERANS  ID": "refID",
  "SIPARIŞ NO": "refID",
  "ŞUBE ID": "branchId",
  "MAĞAZA KODU": "branchId",
  "ALICI İLİ": "recipientProvince",
  "MÜŞTERI İLI": "recipientProvince",
  "ALICI İLÇESİ": "recipientDistrict",
  "MÜŞTERI İLÇESI": "recipientDistrict",
  ENLEM: "latitude",
  BOYLAM: "longitude",
  "ALICI ADI": "recipientName",
  "MÜŞTERI ADI SOYADI": "recipientName",
  "ALICI TELEFONU": "phone",
  "MÜŞTERI NO": "phone",
  "ALICI ADRESİ": "receiverAddress",
  "MÜŞTERI ADRESI": "receiverAddress",
  "PAKET SAYISI": "packageCount",
  "SEPET SAYISI": "packageCount",
  DESİ: "deci",
  BARKOD: "barcode",
  "ÖDEME TİPİ": "collectionType",
  "ÖDEME TIPI": "collectionType",
  TUTAR: "prize",
  "SLOT BAŞLANGICI": "deliveryPeriodStart",
  "SLOT BİTİŞİ": "deliveryPeriodEnd",
};

export const earningExcelKeyMap = {
  "ARAÇ PLAKA": "vehiclePlate",
  "PROJE": "project",
  "TOTAL GÜN": "dayCount",
  "GÜN HAKEDİŞ": "daysEarning",
  "PAKET": "packageCount",
  "PAKET TL": "packageEarning",
  "YAKIT TL": "fuelPayment",
  "EK ÖDEME/KESİNTİ": "additionalPayment",
  "ROTA PRİMİ": "routeBonusPayment",
  "KDV DAHİL": "totalPaymentWithTax",
  "TEVKİFAT": "withholding",
  "ÖDENECEK TUTAR": "finalPayment",
};

export const earningExportExcel = {
  "ARAÇ PLAKA": "34ABC34",
  "PROJE": "PRJ-00",
  "TOTAL GÜN": "10",
  "GÜN HAKEDİŞ": "1000",
  "PAKET": "10",
  "PAKET TL": "1000",
  "YAKIT TL": "1001",
  "EK ÖDEME/KESİNTİ": "1002",
  "ROTA PRİMİ": "1003",
}

export const subcontractorExportExcel = {
  "EMAIL": "x@gmail.com",
  "AD": "A",
  "SOYAD": "B",
  "VKN/TCKN": "11111111111",
  "TELEFON": "5554444444",
  "PLAKA": "34DENEMEEXEL34",
  "IBAN": "TR123456789012345678901234"
}

export const subcontractorExcelKeyMap = {
  "EMAIL": "email",
  "AD": "firstName",
  "SOYAD": "lastName", 
  "VKN/TCKN": "accounting_id",
  "TELEFON": "mobile",
  "PLAKA": "vehiclePlate",
  "IBAN": "iban"
}

export const exportExcel = {
  "REFERANS  ID": "1234",
  "ŞUBE ID": "SB-1.1",
  "AlICI İLİ": "İSTANBUL",
  "ALICI İLÇESİ": "BEŞİKTAŞ",
  ENLEM: "41.07781910153073",
  BOYLAM: "29.0440266",
  "ALICI ADI": "Demir Yıldız",
  "ALICI TELEFONU": "5555555555",
  "ALICI ADRESİ":
    "NİSPETİYE MAH. GAZİ GÜÇNAR SOK. UYGUR İŞ MERKEZİ NO:4, D:7, 34335 BEŞİKTAŞ/İSTANBUL",
  "PAKET SAYISI": "1",
  DESİ: "0",
  BARKOD: "1SD4-5689-12F2-S212",
  "ÖDEME TİPİ": "ONLINE",
  TUTAR: "0",
  "SLOT BAŞLANGICI": "09.00.00",
  "SLOT BİTİŞİ": "17.00.00",
};

// COLUMNS

export const orderTableColumns = [
  { title: "Referans ID", dataIndex: "refNo" },
  { title: "Alıcı Adresi", dataIndex: "recipientAddress" },
  { title: "Alıcı Adı", dataIndex: "recipientName" },
  { title: "Şube", dataIndex: "origin" },
  { title: "Sipariş Durumu", dataIndex: "orderType" },
  { title: "Slot Başlangıcı", dataIndex: "deliveryPeriodStart" },
  { title: "Slot Bitişi", dataIndex: "deliveryPeriodEnd" },
  {
    title: "Sipariş Oluşturulma Tarihi",
    dataIndex: "orderedAt",
    sorter: (a, b) => new Date(b.orderedAt) - new Date(a.orderedAt),
  },
];

export const earningTableColumns = [
  { title: "Plaka", dataIndex: "plate" },
  { title: "Total Gün", dataIndex: "days" },
  { title: "Gün Hakediş", dataIndex: "dailyEarning" },
  { title: "Paket", dataIndex: "packageCount" },
  { title: "Paket ₺", dataIndex: "packageAmount" },
  { title: "Yakıt ₺", dataIndex: "fuelAmount" },
  { title: "Ek Ödeme/Kesinti", dataIndex: "additionalPaymentAmount" },
  { title: "Rota Primi", dataIndex: "routeBonusAmount" },
  { title: "KDV Dahil Final", dataIndex: "includeKdvAmount" },
  { title: "Tevkifat", dataIndex: "withholdingAmount" },
  { title: "Ödenecek Tutar", dataIndex: "amountToBePaid" },
];

export const earningManagementTableColumns = [
  { title: "Proje", dataIndex: "project" },
  { title: "Plaka", dataIndex: "plate" },
  { title: "Tür", dataIndex: "type" },
  { title: "Tarih", dataIndex: "createdAt" },
  { title: "Gelen Fatura", dataIndex: "invoice", copyable: false },
  { title: "Fatura Tutar", dataIndex: "billAmount" },
  { title: "Fatura Durum", dataIndex: "invoiceStatus", copyable: false },
];

export const PaymentTableColumns = [
  { title: "Proje", dataIndex: "project" },
  { title: "Plaka", dataIndex: "plate" },
  { title: "Tür", dataIndex: "type" },
  { title: "Tarih", dataIndex: "createdAt" },
  { title: "Gelen Fatura", dataIndex: "invoice", copyable: false },
  { title: "Fatura Tutar", dataIndex: "billAmount" },
  { title: "Ödeme Durum", dataIndex: "invoiceStatus", copyable: false },
  { title: "Ödeme Tarihi", dataIndex: "paidAt", copyable: false },
];


export const coordinatesTableColumns = [
  { title: "Referans ID", dataIndex: "refNo" },
  { title: "Enlem", dataIndex: "lat" },
  { title: "Boylam", dataIndex: "lng" },
  { title: "Alıcı Adresi", dataIndex: "recipientAddress" },
  { title: "Alıcı Adı", dataIndex: "recipientName" },
  { title: "Şube", dataIndex: "origin" },
  { title: "Sipariş Durumu", dataIndex: "orderType" },
  { title: "Slot Başlangıç/Bitiş", dataIndex: "deliveryPeriod" },
  {
    title: "Sipariş Oluşturulma Tarihi",
    dataIndex: "orderedAt",
    sorter: (a, b) => new Date(b.orderedAt) - new Date(a.orderedAt),
  },
  { title: "", dataIndex: "coordinateButton", copyable: false },
];

export const routeOrderColumns = [
  { dataIndex: "refId", title: "Ref ID" },
  { dataIndex: "receiverName", title: "Alıcı" },
  { dataIndex: "receiverAddress", title: "Adres" },
  { dataIndex: "slotRange", title: "Slot" },
];

export const routeColumns = [
  { title: "Grup", dataIndex: "routeGroup" },
  { title: "Rota Kodu", dataIndex: "routeNum" },
  { title: "İhale Tutarı", dataIndex: "routePrice" },
  { title: "Durum", dataIndex: "routeStatus" },
  { title: "KM", dataIndex: "plannedDistance" },
  { title: "Süre (Dakika)", dataIndex: "plannedDuration" },
  { title: "Şube", dataIndex: "branchName" },
  { title: "Tamamlanan Sipariş", dataIndex: "completedOrders" },
  { title: "Toplam Sipariş", dataIndex: "totalOrders" },
  { title: "Kurye Atama", dataIndex: "courierAssignment", copyable: false },
  { title: "Onay Durumu ", dataIndex: "approvedButton", copyable: false },
  { title: "Onay ve Kurye Atama", dataIndex: "directCourierAssignment", copyable: false },
  { title: "Rotayı Kapat ", dataIndex: "routeEnd", copyable: false },
];

export const debitColumns = [
  { dataIndex: "courierName", title: "Kurye Adı" },
  { dataIndex: "plate", title: "Plaka" },
  { dataIndex: "phone", title: "Kurye Telefon Numarası" },
  { dataIndex: "branch", title: "Şube" },
  { dataIndex: "fullName", title: "Ad/Soyad" },
  { dataIndex: "orderId", title: "Sipariş ID" },
  { dataIndex: "refID", title: "Sipariş Referans ID" },
  { dataIndex: "slot", title: "Slot" },
  { dataIndex: "address", title: "Adres Bilgileri" },
  { dataIndex: "nonDeliveryTime", title: "Teslim Edilememe Saati" },
  {
    dataIndex: "numberofUndeliverablePackages",
    title: "Teslim Edilemeyen Paket Sayısı",
  },
];

export const earningReportTableColumns = [
  { title: "Cari", dataIndex: "current" },
  { title: "Plaka", dataIndex: "plate" },
  { title: "Total Gün", dataIndex: "days" },
  { title: "Gün Hakediş", dataIndex: "dailyEarning" },
  { title: "Paket", dataIndex: "packageCount" },
  { title: "Paket ₺", dataIndex: "packageAmount" },
  { title: "Yakıt ₺", dataIndex: "fuelAmount" },
  { title: "Ek Ödeme/Kesinti", dataIndex: "additionalPaymentAmount" },
  { title: "Rota Primi", dataIndex: "routeBonusAmount" },
  { title: "KDV Dahil Final", dataIndex: "includeKdvAmount" },
  { title: "Tevkifat", dataIndex: "withholdingAmount" },
  { title: "Ödenecek Tutar", dataIndex: "amountToBePaid" },
  { title: "Gelen Fatura", dataIndex: "invoiceArrived" },
  { title: "Fatura Tutar", dataIndex: "inoviceAmount" },
  { title: "Fatura Durum", dataIndex: "inoviceStatus" },
  { title: "Ödeme Durum", dataIndex: "paymentStatus" },
];

export const RouteReportColumns = [
  { dataIndex: "routeID", title: "Rota ID" },
  { dataIndex: "routeCode", title: "Rota Kodu" },
  { dataIndex: "plate", title: "Plaka" },
  { dataIndex: "courier", title: "Kurye" },
  { dataIndex: "createdAt", title: "Rota Oluşturulma Tarihi" },
  { dataIndex: "pickedAt", title: "Rota Alınma Saati" },
  { dataIndex: "routeStartedAt", title: "Rota Başlangıç Saati" },
  { dataIndex: "routeEndedAt", title: "Rota Bitiş Saati" },
  { dataIndex: "orderCount", title: "Sipariş Sayısı" },
  { dataIndex: "packageCount", title: "Paket Sayısı" },
  { dataIndex: "plannedDistance", title: "Planlanan KM" },
  { dataIndex: "plannedDuration", title: "Planlanan Çalışma Süresi" },
  { dataIndex: "finalDuration", title: "Gerçekleşen Çalışma Süresi" },
  { dataIndex: "deliveredPackages", title: "Teslim Edilen Paket Sayısı" },
  {
    dataIndex: "undeliveredPackages",
    title: "Teslim Edilemeyen Paket Sayısı",
  },
  {
    dataIndex: "deliveredOnTimePackages",
    title: "Zamanında Teslim Edilen Paket Sayısı",
  },
  {
    dataIndex: "deliveredDelayedPackages",
    title: "Zamanında Teslim Edilemeyen Paket Sayısı",
  },
  {
    dataIndex: "deliveredDelayedPackagesFromOrigin",
    title: "Şube Kaynaklı Geciken Paket Sayısı",
  },
  {
    dataIndex: "deliveredDelayedPackagesByCourier",
    title: "Kurye Kaynaklı Geciken Paket Sayısı",
  },
  { dataIndex: "deliveredOrders", title: "Teslim Edilen Sipariş Sayısı" },
  {
    dataIndex: "undeliveredOrders",
    title: "Teslim Edilemeyen Sipariş Sayısı",
  },
  {
    dataIndex: "deliveredOnTimeOrders",
    title: "Zamanında Teslim Edilen Sipariş Sayısı",
  },
  {
    dataIndex: "deliveredDelayedOrders",
    title: "Zamanında Teslim Edilemeyen Sipariş Sayısı",
  },
  {
    dataIndex: "deliveredDelayedOrdersFromOrigin",
    title: "Şube Kaynaklı Geciken Sipariş Sayısı",
  },
  {
    dataIndex: "deliveredDelayedOrdersByCourier",
    title: "Kurye Kaynaklı Geciken Sipariş Sayısı",
  },
  { dataIndex: "paidOnline", title: "Online Ödeme Tutarı" },
  { dataIndex: "paidCash", title: "Nakit Ödeme Tutarı" },
  { dataIndex: "paidCard", title: "Kart Ödeme Tutarı" },
  { dataIndex: "bringoCost", title: "İhale Tutarı" },
];

export const performaneColumns = [
  { dataIndex: "courierName", title: "Dağıtıcı Adı" },
  { dataIndex: "plate", title: "Plaka" },
  { dataIndex: "distributedBranch", title: "Dağıtım Yapılan Proje/Şube" },
  { dataIndex: "orderCount", title: "Sipariş Adedi" },
  // { dataIndex: "delayedOrderCount", title: "Gecikmeli Sipariş Adedi" },
];

export const liveTrackingColumns = [
  { dataIndex: "courierName", title: "Kurye" },
  { dataIndex: "online", title: "Online" },
  { dataIndex: "plate", title: "Plaka" },
  { dataIndex: "courierMobile", title: "Telefon" },
  { dataIndex: "branch", title: "Şube" },
  { dataIndex: "plannedDistance", title: "Planlanan KM" },
  { dataIndex: "plannedDuration", title: "Planlanan Süre" },
  { dataIndex: "orderCount", title: "Sipariş Sayısı" },
  { dataIndex: "deliveredOrderCount", title: "Teslim Edilen Sipariş Sayısı" },
  { dataIndex: "createdAt", title: "Rota Oluşturulma Saati" },
];

export const trackingOrderColumns = [
  { dataIndex: "refID", title: "Ref ID" },
  { dataIndex: "createdAt", title: "Sipariş Tarihi" },
  { dataIndex: "status", title: "Durum" },
  { dataIndex: "receiver", title: "Alıcı" },
  { dataIndex: "receiverMobile", title: "Alıcı Telefon" },
  { dataIndex: "destination", title: "Alıcı Adres" },
  { dataIndex: "branch", title: "Şube" },
  { dataIndex: "deci", title: "Desi" },
  { dataIndex: "deliveryDate", title: "Teslim Tarihi" },
  { dataIndex: "packageCount", title: "Paket Sayısı" },
];

export const orderReportColumns = [
  { dataIndex: "refID", title: "Referans ID" },
  { dataIndex: "id", title: "Sipariş ID" },
  { dataIndex: "project", title: "Proje" },
  { dataIndex: "current", title: "Müşteri" },
  { dataIndex: "currentMobile", title: "Müşteri Telefonu" },
  { dataIndex: "platform", title: "Platform" },
  { dataIndex: "orderedAt", title: "Sipariş Verilme Tarihi" },
  { dataIndex: "region", title: "İl/İlçe" },
  { dataIndex: "branchCode", title: "Çıkış Şube Kodu" },
  { dataIndex: "branch", title: "Şube" },
  { dataIndex: "orderStatus", title: "Sipariş Durumu" },
  { dataIndex: "returned", title: "İptal / İade" },
  { dataIndex: "cancelReason", title: "İptal Sebebi" },
  { dataIndex: "undeliverReason", title: "Teslim Edememe Sebebi" },
  { dataIndex: "paymentType", title: "Ödeme Tipi" },
  { dataIndex: "orderDeliveryDate", title: "Sipariş Dağıtım Tarihi" },
  { dataIndex: "orderSlot", title: "Sipariş Slotu" },
  { dataIndex: "deliveryDateofOrdertoCourier", title: "Kuryeye Teslim Tarihi" },
  {
    dataIndex: "deliveryDateofOrdertoCustomer",
    title: "Alıcıya Teslim Tarihi",
  },
  { dataIndex: "deliveryTime", title: "Teslimat Süresi" },
  { dataIndex: "totalAmount", title: "Toplam Tutar" },
  { dataIndex: "totalRefundedAmount", title: "Toplam İade Edilen Tutar" },
  { dataIndex: "amountReceivedfromCustomer", title: "Alıcıdan Alınan Tutar" },
  { dataIndex: "receiverCoordinates", title: "Alıcı Kordinatları" },
  {
    dataIndex: "orderClosingCoordinates",
    title: "Siparişin Kapanış Kordinatları",
  },
  {
    dataIndex: "closingCoordinatesBetweenReceiver",
    title: "Alıcı ve Kapanış Arası Mesafe",
  },
  { dataIndex: "distributorName", title: "Dağıtıcı Adı" },
  { dataIndex: "distributorPlate", title: "Dağıtıcı Plaka" },
  { dataIndex: "distributorMobile", title: "Dağıtıcı Telefon Numarası" },
];

export const distributorColumns = [
  { dataIndex: "courierName", title: "Dağıtıcı Adı" },
  {
    dataIndex: "dailyOrders",
    title: "Günlük Dağıtıma Çıkarılan Sipariş Adedi",
  },
  { dataIndex: "deliveredOrders", title: "Teslim Edilen Sipariş Adedi" },
  { dataIndex: "undeliveredOrders", title: "Teslim Edilemeyen Sipariş Adedi" },
  {
    dataIndex: "pickedFirstOrderDate",
    title: "İlk Siparişin Teslim Alınma Tarihi",
  },
  {
    dataIndex: "deliveredLastOrderDate",
    title: "Son Siparişin Teslim Edilme Tarihi",
  },
  { dataIndex: "between", title: "İki Sipariş Arasında Geçen Zaman Aralığı" },
];

export const debitReportColumns = [
  { dataIndex: "date", title: "Tarih" },
  { dataIndex: "plate", title: "Plaka" },
  { dataIndex: "courier", title: "Kurye" },
  { dataIndex: "phone", title: "Telefon" },
  {
    dataIndex: "undeliveredOrders",
    title: "Teslim Edilemeyen Sipariş ID'leri",
  },
  {
    dataIndex: "undeliveredPackages",
    title: "Teslim Edilemeyen Paket ID'leri",
  },
  { dataIndex: "paidCash", title: "Üzerindeki Nakit" },
  { dataIndex: "paidCard", title: "Üzerindeki Kredi Kartı" },
];

export const riskyOrderTableColumns = [
  { title: "Referans ID", dataIndex: "refID" },
  { title: "Sipariş Durumu", dataIndex: "orderStatus" },
  { title: "Plaka", dataIndex: "plate" },
  { title: "Kurye", dataIndex: "courier" },
  { title: "Slot", dataIndex: "slot" },
  { title: "Adres", dataIndex: "address" },
  { title: "Oluşturulma Tarihi", dataIndex: "createdAt" },
  { title: "Dağıtım Tarihi", dataIndex: "distributedAt" },
  { title: "Şube", dataIndex: "branch" },
];

export const paymentTypeOptions = [
  { value: "cash", label: "Nakit" },
  { value: "card", label: "Kart" },
  { value: "paid", label: "Online" },
];

export const uniqueSymbols = [
  "≈",
  "∞",
  "∑",
  "○",
  "●",
  "■",
  "□",
  "▲",
  "▼",
  "◆",
  "◇",
  "★",
  "☆",
  "☀",
  "☁",
  "☂",
  "☃",
  "☄",
  "☎",
  "☏",
  "☑",
  "☒",
  "✿",
  "✸",
  "✹",
  "✺",
  "✻",
  "✼",
  "✽",
  "✾",
  "✿",
  "❀",
  "❁",
  "❂",
  "❃",
  "❄",
  "❅",
  "❆",
  "❇",
  "❈",
  "❉",
  "❊",
  "❋",
  "❖",
  "❧",
  "❥",
  "❦",
  "❧",
  "☘",
  "☙",
  "☚",
  "☛",
  "☜",
  "☝",
  "☞",
  "☟",
  "☠",
  "☡",
  "☢",
  "☣",
  "☤",
  "☥",
  "☦",
  "☧",
  "☨",
  "☩",
  "☪",
  "☫",
  "☬",
  "☭",
  "☮",
  "☯",
  "☸",
  "☹",
  "☺",
  "☻",
  "☼",
  "☽",
  "☾",
  "☿",
  "♀",
  "♂",
  "♁",
  "♃",
  "♄",
  "♅",
  "♆",
  "♇",
  "♈",
  "♉",
  "♊",
  "♋",
  "♌",
  "♍",
  "♎",
  "♏",
  "♐",
  "♑",
  "♒",
  "♓",
  "♔",
  "♕",
  "♖",
  "♗",
  "♘",
  "♙",
  "♚",
  "♛",
  "♜",
  "♝",
  "♞",
  "♟",
  "♠",
  "♡",
  "♢",
  "♣",
  "♤",
  "♥",
  "♦",
  "♧",
  "♨",
  "♩",
  "♪",
  "♫",
  "♬",
  "♭",
  "♮",
  "♯",
  "⚀",
  "⚁",
  "⚂",
  "⚃",
  "⚄",
  "⚅",
  "⚆",
  "⚇",
  "⚈",
  "⚉",
  "⚊",
  "⚋",
  "⚌",
  "⚍",
  "⚎",
  "⚏",
  "⚐",
  "⚑",
  "⚒",
  "⚓",
  "⚔",
  "⚕",
  "⚖",
  "⚗",
  "⚘",
  "⚙",
  "⚚",
  "⚛",
  "⚜",
  "⚝",
  "⚞",
  "⚟",
  "⚠",
  "⚡",
  "⚢",
  "⚣",
  "⚤",
  "⚥",
  "⚦",
  "⚧",
  "⚨",
  "⚩",
  "⚪",
  "⚫",
  "⚬",
  "⚭",
  "⚮",
  "⚯",
  "⚰",
  "⚱",
  "⚲",
  "⚳",
  "⚴",
  "⚵",
  "⚶",
  "⚷",
  "⚸",
  "⚹",
  "⚺",
  "⚻",
  "⚼",
  "⚽",
  "⚾",
  "⚿",
  "⛀",
  "⛁",
  "⛂",
  "⛃",
  "⛄",
  "⛅",
  "⛆",
  "⛇",
  "⛈",
  "⛉",
  "⛊",
  "⛋",
  "⛌",
  "⛍",
  "⛎",
  "⛏",
  "⛐",
  "⛑",
  "⛒",
  "⛓",
  "⛔",
  "⛕",
  "⛖",
  "⛗",
  "⛘",
  "⛙",
  "⛚",
  "⛛",
  "⛜",
  "⛝",
  "⛞",
  "⛟",
  "⛠",
  "⛡",
  "⛢",
  "⛣",
  "⛤",
  "⛥",
  "⛦",
  "⛧",
  "⛨",
  "⛩",
  "⛪",
  "⛫",
  "⛬",
  "⛭",
  "⛮",
  "⛯",
  "⛰",
  "⛱",
  "⛲",
  "⛳",
  "⛴",
  "⛵",
  "⛶",
  "⛷",
  "⛸",
  "⛹",
  "⛺",
  "⛻",
  "⛼",
  "⛽",
  "⛾",
  "⛿",
];