import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putChangeOrderLocation = createAsyncThunk(
    'project/putProject',
    async ({selectedOrderID, updatedData}, { rejectWithValue }) => {
      // try {
        const response = await axios.put(`/order/change-of-address/${selectedOrderID}`, updatedData);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 
