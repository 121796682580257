import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putVehicleApprovel = createAsyncThunk(
  "putVehicleApprovel",
  async (updatedData, { rejectWithValue }) => {
    const response = await axios.put(`/user/subcontractor-and-vehicle-from-mobile`, updatedData);
    return response.data;
  }
);
