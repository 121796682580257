import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setBranch = createAsyncThunk(
    'branch/setBranch',
    async (requestData, { rejectWithValue }) => {
        // try {
            const response = await axios.post('/branch', requestData);
            return response.data;
        // } catch (error) {
        //     if (error.response && error.response.status === 502) {
        //         return rejectWithValue('502 Bad Gateway hatası oluştu, lütfen daha sonra tekrar deneyin.');
        //     } else {
        //         return rejectWithValue('Bir hata oluştu, lütfen daha sonra tekrar deneyin.');
        //     }
        // }
    }
); 


 