import axios from "axios";
import { store } from "../store/store";
import { logout } from "../store/auth/logout";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Api-Key': process.env.REACT_APP_API_KEY,
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'Project-Name': 'BringoApp',
        'Content-Type': 'application/json',
    }
})

api.interceptors.request.use(async (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Cache-Control'] = 'no-store, no-cache, must-revalidate, proxy-revalidate';
    config.headers.Pragma = 'no-cache';

    if (config.data) {
        config.data = JSON.stringify(config.data);
    }

    return config;
});

api.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        console.log(error)
        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error)
    }
);

export default api;