import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const patchRouteEnd = createAsyncThunk(
  'route/patchRouteEnd',
  async (routeID, { rejectWithValue }) => {
    // try {
      const response = await axios.patch(`/route/end/${routeID}`);
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);