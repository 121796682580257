import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteSubcontractor = createAsyncThunk(
    'user/deleteSubcontractor',
    async (userId, { rejectWithValue }) => {
      // try {
        const response = await axios.delete(`/subcontractor/${userId}`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 

  