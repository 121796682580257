import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putApproveSubcontractor = createAsyncThunk(
  "putApproveSubcontractor",
  async ({ requestBody }, { rejectWithValue }) => {
    const response = await axios.put(
      `/user/approve-from-accounting`,
      requestBody
    );
    return response.data;
  }
);
