import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCourierApprovelList = createAsyncThunk(
  "getCourierApproveList",
  async (_, { rejectWithValue }) => {
    // try {
    const response = await axios.get(`/user/courier-from-mobile-list`);
    return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);
