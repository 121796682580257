import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPaymentWaitingEarnings = createAsyncThunk(
    'earning/getPaymentWaitingEarnings',
    async (_, { rejectWithValue }) => {
      // try {
        const response = await axios.get(`/claim/payment-pending-list`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  );