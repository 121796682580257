import { createSlice } from '@reduxjs/toolkit';
import { getRouteDetail } from './getRouteDetails.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const getRouteDetailSlice = createSlice({
  name: 'getRouteDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRouteDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRouteDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRouteDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getRouteDetailSlice.reducer;