import { createSlice } from '@reduxjs/toolkit';
import { getRouteVehicle } from './getRouteVehicle.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const getRouteVehicleSlice = createSlice({
  name: 'getRouteVehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRouteVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRouteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRouteVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getRouteVehicleSlice.reducer;