import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putApproveUser = createAsyncThunk(
    'putApproveUser',
    async (userID, { rejectWithValue }) => {
      // try {
        const response = await axios.put(`/user/user-approve/${userID}`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 
