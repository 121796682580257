import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteBranch = createAsyncThunk(
    'branch/deleteBranch',
    async (branchId, { rejectWithValue }) => {
      // try {
        const response = await axios.delete(`/branch/${branchId}`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 

  