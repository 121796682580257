import {
  DashboardOutlined,
  FileTextOutlined,
  ApartmentOutlined,
  UserSwitchOutlined,
  BankOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

const icons = {
  DashboardOutlined,
  FileTextOutlined,
  ApartmentOutlined,
  UserSwitchOutlined,
  BankOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
};

const supervisorDashboard = {
  id: "supervisor-dashboard",
  title: "Ön İzleme",
  type: "item",
  items: [
    {
      id: "supervisor-dashboard",
      title: "Ön İzleme",
      type: "item",
      url: "/supervisor-dashboard",
      icon: <icons.DashboardOutlined />,
    },
  ],
};

const projectManagement = {
  id: "supervisor-project-management",
  title: "Proje",
  type: "item",
  items: [
    {
      id: "supervisor-project-management",
      title: "Proje",
      type: "item",
      url: "/supervisor-project-management",
      icon: <icons.ApartmentOutlined />,
    },
  ],
};

const branchManagement = {
  id: "supervisor-branch-management",
  title: "Şube",
  type: "item",
  items: [
    {
      id: "supervisor-branch-management",
      title: "Şube",
      type: "item",
      url: "/supervisor-branch-management",
      icon: <icons.BankOutlined />,
    },
  ],
};

const userManagement = {
  id: "supervisor-user",
  title: "Kullanıcı",
  type: "group",
  icon: <icons.UserSwitchOutlined />,
  items: [
    {
      id: "supervisor-courier-management",
      title: "Kurye",
      type: "item",
      url: "/supervisor-courier-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-supervisor-management",
      title: "Yönetici",
      type: "item",
      url: "/supervisor-supervisorUser-management",
      icon: <icons.UserOutlined />,
    },

    {
      id: "supervisor-operation-management",
      title: "Operasyon",
      type: "item",
      url: "/supervisor-operation-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-client-management",
      title: "Müşteri",
      type: "item",
      url: "/supervisor-client-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-clientOperations-management",
      title: "Müşteri Operasyon",
      type: "item",
      url: "/supervisor-clientOperation-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-clientManager-management",
      title: "Müşteri Yönetici Yönetimi",
      type: "item",
      url: "/supervisor-clientManager-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-accountant-management",
      title: "Muhasebe",
      type: "item",
      url: "/supervisor-accountant-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "supervisor-vehicle-management",
      title: "Araç",
      type: "item",
      url: "/supervisor-vehicle-management",
      icon: <icons.UserOutlined />,
    },
    {
      id: "subcontractor",
      title: "Taşeron",
      type: "item",
      url: "/subcontractor",
      icon: <icons.UserOutlined />,
    },
  ],
};

const userApproveManagement = {
  id: "supervisor-user-approve",
  title: "Kullanıcı Onayı",
  type: "group",
  icon: <icons.UsergroupAddOutlined />,
  items: [
    {
      id: "supervisor-courier-approve",
      title: "Kurye Onay",
      type: "item",
      url: "/supervisor-courier-approve",
      icon: <icons.UserAddOutlined />,
    },
    {
      id: "supervisor-vehicle-approve",
      title: "Araç Onay",
      type: "item",
      url: "/supervisor-vehicle-approve",
      icon: <icons.UserAddOutlined />,
    },
  ],
};

export const supervisor = {
  supervisorDashboard,
  projectManagement,
  branchManagement,
  userManagement,
  userApproveManagement,
};
