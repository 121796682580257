import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const setRoute = createAsyncThunk(
  "route/setRoute",
  async ({ orderIDList, routeCount }, { rejectWithValue }) => {
    // try {
    const response = await axios.post("/route", {
      orders: orderIDList,
      vehicles: [],
      ignoreTimeWindow: true,
      optimizeWaypoints: true,
      routeCount: routeCount ? routeCount : 0,
    });
    return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);
