import { createSlice } from '@reduxjs/toolkit';
import { putRouteVehicle } from './putRouteVehicle.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const putRouteVehicleSlice = createSlice({
  name: 'putRouteVehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putRouteVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(putRouteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(putRouteVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default putRouteVehicleSlice.reducer;