import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putClient = createAsyncThunk(
    'putClient',
    async (updatedData, { rejectWithValue }) => {
        const response = await axios.put(`/user/client-admin`, updatedData);
        return response.data;
    }
  ); 
