import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEarningsWithProjects = createAsyncThunk(
  "earning/getEarningsWithProjects",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/claim/claim-for-project-list`, {
        params: { startDate, endDate }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
