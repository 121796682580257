import { createSlice } from '@reduxjs/toolkit';
import { setOrderForm } from './setOrderForm.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const setOrderFormSlice = createSlice({
  name: 'formOrder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setOrderForm.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(setOrderForm.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(setOrderForm.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default setOrderFormSlice.reducer;