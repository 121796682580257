import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getVehicleApprovelList = createAsyncThunk(
  "getVehicleApprovelList",
  async (_, { rejectWithValue }) => {
    // try {
    const response = await axios.get(`/user/vehicle-from-mobile-list`);
    return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);
