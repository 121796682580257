import { createSlice } from '@reduxjs/toolkit';
import { getRouteOrder } from './getRouteOrder.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const getRouteOrderSlice = createSlice({
  name: 'getRouteOrder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRouteOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRouteOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRouteOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getRouteOrderSlice.reducer;