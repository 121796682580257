import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProvinces = createAsyncThunk(
    'provinces/getProvinces',
    async (_, { rejectWithValue }) => {
      // try {
        const response = await axios.get(`/common/provinces`);
        const data = response.data;

        // Sort data by name with Turkish characters
        data.sort((a, b) => a.name.localeCompare(b.name, 'tr-TR'));

        return data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
);