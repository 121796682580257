import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const putApproveSubcontractorAndVehicle = createAsyncThunk(
    'putApproveSubcontractorAndVehicle',
    async ({ userID, operationApproveMessage }, { rejectWithValue }) => {
      try {
        const response = await axios.put(
          `/user/subcontractor-approve-from-operation/${userID}`,
          { operationApproveMessage }
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );