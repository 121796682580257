import { createSlice } from "@reduxjs/toolkit";
import { getAccountant } from "./getAccountant.repository";

const initialState = {
  loading: "idle",
  data: null,
  error: null,
};

const getAccountantSlice = createSlice({
  name: "getAccountant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountant.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAccountant.fulfilled, (state, action) => {
        state.loading = "idle";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getAccountant.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.payload;
      });
  },
});

export default getAccountantSlice.reducer;
