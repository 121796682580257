// getNotification.slice.js
import { createSlice } from '@reduxjs/toolkit';
import { readNotification } from './readNotification.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const readNotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readNotification.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(readNotification.fulfilled, (state) => {
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default readNotificationSlice.reducer;