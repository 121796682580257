import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { message } from 'antd';

export const authLogin = createAsyncThunk(
  'auth/login',
  async ({ mobile, password }, thunkAPI) => {
    try {
      const response = await axios.post(process.env.REACT_APP_AUTH_BASE_URL, {
        mobile,
        password,
        grantType: 'password',
        platform: 'portal',
      });
      const token = response.data.accessToken;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      sessionStorage.setItem('auth', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        message.error("Hatalı mail yada şifre");
        return thunkAPI.rejectWithValue("Hatalı mail yada şifre");
      }
      message.error(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);