export const colors = {
    // paper & background
    white: '#ffffff',
    // primary
    primaryLight: '#f3e5e5',
    primaryMain: '#e1cfcf',
    primaryDark: '#d1b8b8',
    primary200: '#f7ecec',
    primary800: '#bfa8a8',
    
    secondaryLight: '#f0f8ff',
    secondaryMain: '#e6f2ff',
    secondaryDark: '#b3c6e0',
    secondary200: '#d9e6ff',
    secondary800: '#809fcc',
    // success Colors
    successLight: '#b9f6ca',
    success200: '#69f0ae',
    successMain: '#00e676',
    successDark: '#00c853',
    // error
    errorLight: '#ef9a9a',
    errorMain: '#f44336',
    errorDark: '#c62828',
    // orange
    orangeLight: '#fbe9e7',
    orangeMain: '#ffab91',
    orangeDark: '#d84315',
    // warning
    warningLight: '#fff8e1',
    warningMain: '#ffe57f',
    warningDark: '#ffc107',
    //la ferrari
    laFerrari: '#ff2800',
    // grey
    grey50: '#f8fafc',
    grey100: '#eef2f6',
    grey200: '#e3e8ef',
    grey300: '#cdd5df',
    grey500: '#697586',
    grey600: '#4b5565',
    grey700: '#364152',
    grey900: '#121926',
  };