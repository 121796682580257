import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteProject = createAsyncThunk(
    'project/deleteProject',
    async (projectId, { rejectWithValue }) => {
      // try {
        const response = await axios.delete(`/project/${projectId}`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 

  