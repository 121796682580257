import React from 'react';
import { Modal, Button } from 'antd';

const SuccessModal = ({ open, handleClose, title, titleColor, description }) => {
    const defaultTitleColor = titleColor || 'gray'; // Eğer titleColor boşsa varsayılan olarak gri rengi kullan

    return (
        <Modal
            title={<span style={{ color: defaultTitleColor }}>{title}</span>}
            open={open}
            centered
            onCancel={handleClose}
            footer={[
                <Button key="ok" onClick={handleClose} type="primary">
                    Tamam
                </Button>,
            ]}
        >
            <p>{description}</p>
        </Modal>
    );
};

export default SuccessModal;
