import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLastLocation = createAsyncThunk(
  "locations/getLastLocation",
  async (_, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      
      const response = await axios.get(
        `${process.env.REACT_APP_LOCATION_BASE_URL}/all-last-locations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
