import { createSlice } from '@reduxjs/toolkit';
import { getRouteByDate } from './getRouteByDate.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const getRouteByDatelice = createSlice({
  name: 'getRoute',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRouteByDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRouteByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRouteByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getRouteByDatelice.reducer;