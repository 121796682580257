import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putRejectBill = createAsyncThunk(
  "bill/putRejectBill",
  async ({ claimID, invoiceRejectedMessage }, { rejectWithValue }) => {
    const response = await axios.put(
      `/claim/accountant-invoice-rejected/${claimID}`,
      { invoiceRejectedMessage }
    );
    return response.data;
  }
);
