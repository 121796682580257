import { createSlice } from '@reduxjs/toolkit';
import { putOperations } from './putOperations.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const putOperationsSlice = createSlice({
  name: 'putOperations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putOperations.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(putOperations.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(putOperations.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default putOperationsSlice.reducer;