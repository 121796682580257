import { createSlice } from '@reduxjs/toolkit';
import { setDebitReportFilter } from './setDebitReportFilter.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const setDebitReportFilterSlice = createSlice({
  name: 'setDebitReportFilter',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setDebitReportFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDebitReportFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(setDebitReportFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { clearData } = setDebitReportFilterSlice.actions;
export default setDebitReportFilterSlice.reducer;