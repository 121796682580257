import { Divider } from "antd";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
//
import Logo from "../../assets/images/logo.png";

function AuthLayout() {
  return (
    <StyledDiv>
      <LogoDiv>
        <StyledImg src={Logo} alt="logo" />
      </LogoDiv>
      <Outlet />
      <StyledFooter>
        <Divider />
        Tüm hakları saklıdır HeyBringo © 2024
      </StyledFooter>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LogoDiv = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${props => props.theme.primaryMain};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  height: 60%;
`;


const StyledFooter = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-bottom: 24px;
`;

export default AuthLayout;