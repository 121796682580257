import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putCourierVehicleAssignment = createAsyncThunk(
    'putCourierVehicleAssignment/put',
    async ({selectedVehicleID, courierData}, { rejectWithValue }) => {
        const response = await axios.put(`/user/assign-courier-to-vehicle/${selectedVehicleID}`, courierData);
        return response.data;
    }
  ); 
