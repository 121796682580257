import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setAddressToCoordinate = createAsyncThunk(
    'order/setAddressToCoordinate',
    async (requestData, { rejectWithValue }) => {
      // try {
        const response = await axios.post('/order/address-to-coordinates', requestData);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 
