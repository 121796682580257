import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putCourierApprovel = createAsyncThunk(
  "putCourierApprovel",
  async (updatedData, { rejectWithValue }) => {
    const response = await axios.put(`/user/courier-from-mobile`, updatedData);
    return response.data;
  }
);
