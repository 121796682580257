import { createSlice } from '@reduxjs/toolkit';
import { setOrderReportFilter } from './setOrderReportFilter.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const setOrderReportFilterSlice = createSlice({
  name: 'setOrderReportFilter',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setOrderReportFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(setOrderReportFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(setOrderReportFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { clearData } = setOrderReportFilterSlice.actions;
export default setOrderReportFilterSlice.reducer;