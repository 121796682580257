import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getBranch = createAsyncThunk(
    'branch/getBranch',
    async (_, { rejectWithValue }) => {
      // try {
        const response = await axios.get(`/branch`);
        const data = response.data;
        if (Array.isArray(data)) {
          data.sort((a, b) => a.name.localeCompare(b.name, 'tr'));
        }
        return data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  );