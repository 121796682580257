import { createSlice } from '@reduxjs/toolkit';
import { deleteClientOperations } from './deleteClientOperations.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const deleteClientOperationsSlice = createSlice({
  name: 'deleteClientOperations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteClientOperations.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(deleteClientOperations.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(deleteClientOperations.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default deleteClientOperationsSlice.reducer;