import { createSlice } from '@reduxjs/toolkit';
import { setEarning } from './setEarning.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const setEarningSlice = createSlice({
  name: 'earning',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setEarning.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(setEarning.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(setEarning.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default setEarningSlice.reducer;