import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setSubcontractor = createAsyncThunk(
    'user/setSubcontractor',
    async (requestData, { rejectWithValue }) => {
      // try {
        const response = await axios.post('/subcontractor', { subcontractors: requestData });
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 


 