import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putUpdateAccountingID = createAsyncThunk(
  "putUpdateAccountingID",
  async ({ requestBody, userID }, { rejectWithValue }) => {
    const response = await axios.put(
      `/user/subcontractor-from-accounting/${userID}`,
      requestBody
    );
    return response.data;
  }
);
