import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setClient = createAsyncThunk(
    'setClient',
    async (requestData, { rejectWithValue }) => {
        const response = await axios.post('/user/client-admin', requestData);
        return response.data;
    }
  ); 

