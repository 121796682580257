import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putPaidBill = createAsyncThunk(
  "bill/putPaidBill",
  async ({ claimIDs, accountantInvoicePaidAt }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/claim/accountant-invoice-paid`, {
        accountantInvoicePaidAt,
        claimIDs,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
