import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteAccountant = createAsyncThunk(
    'deleteAccountant',
    async (email, { rejectWithValue }) => {
      // try {
        const response = await axios.delete(`/user/${email}/accountantRole`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 

  