import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putAccountant = createAsyncThunk(
    'putAccountant',
    async (updatedData, { rejectWithValue }) => {
      // try {
        const response = await axios.put(`/user/accountant`, updatedData);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 
