import {
  DashboardOutlined,
  DollarOutlined,
  FileTextOutlined
} from "@ant-design/icons";

const icons = {
  DashboardOutlined,
  DollarOutlined,
  FileTextOutlined
};

// const accountantDashboard = {
//   id: "accountant-dashboard",
//   title: "Ön İzleme",
//   type: "item",
//   items: [
//     {
//       id: "accountant-dashboard",
//       title: "Ön İzleme",
//       type: "item",
//       url: "/accountant-dashboard",
//       icon: <icons.DashboardOutlined />,
//     },
//   ],
// };

const earnings = {
  id: "earning-accountant",
  title: "Hakediş",
  type: "group",
  icon: <icons.DollarOutlined />,
  items: [
    {
      id: "earning",
      title: "Dönem Sonu",
      type: "item",
      url: "/accountant-earning",
    },
    {
      id: "earningManagement",
      title: "Dönem Sonu Yönetim",
      type: "item",
      url: "/accountant-earning-management",
    },
    {
      id: "earningPaymentManagement",
      title: "Ödeme Yönetim",
      type: "item",
      url: "/accountant-earning-payment-management",
    },
  ],
};

const currentApproveManagement = {
  id: 'accountant-user-approve',
  title: 'Taşeron Onay Listesi',
  type: 'item',
  items: [
    {
      id: 'accountant-subcontractor-approve',
      title: 'Taşeron Onay',
      type: 'item',
      url: '/accountant-subcontractor-approve',
      icon: <icons.FileTextOutlined />
    },
  ]
};

export const accountant = {
  // accountantDashboard,
  earnings,
  currentApproveManagement,
};
