import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getSubcontractorId = createAsyncThunk(
    'user/getSubcontractorId',
    async (id, { rejectWithValue }) => {
      // try {
        const response = await axios.get(`/subcontractor/${id}`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  ); 
