import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPlannerOrder = createAsyncThunk(
  'order/fetchOrderListBySlotTime',
  async ({ branchCode, timePeriods }, { rejectWithValue }) => {
    // try {
      const requestBody = {
        branchCode,
        timePeriods,
        type: "notRouted"
      };
      const response = await axios.post('/order/order-list-by-time-window', requestBody);
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);