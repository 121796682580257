import { createSlice } from '@reduxjs/toolkit';
import { setVehicle } from './setVehicle.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const setVehicleSlice = createSlice({
  name: 'postVehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setVehicle.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(setVehicle.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(setVehicle.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default setVehicleSlice.reducer;