import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLocations = createAsyncThunk(
  "locations/getLocations",
  async ({ routeIDs }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_LOCATION_BASE_URL}/routes/all-locations`,
        {
          params: {
            routeIds: routeIDs,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
