import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAccountantList = createAsyncThunk(
  "getAccountantList",
  async (_, { rejectWithValue }) => {
    const response = await axios.get(`/user?roles=accountantRole`);
    return response.data;
  }
);
