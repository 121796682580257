import { createSlice } from "@reduxjs/toolkit";
import { getAccountantList } from "./getAccountantList.repository";

const initialState = {
  loading: "idle",
  data: null,
  error: null,
};

const getAccountantListSlice = createSlice({
  name: "getAccountantList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountantList.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAccountantList.fulfilled, (state, action) => {
        state.loading = "idle";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getAccountantList.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.payload;
      });
  },
});

export default getAccountantListSlice.reducer;
