import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putRejectSubcontractorAndVehicle = createAsyncThunk(
    'putRejectSubcontractorAndVehicle',
    async (userID, { rejectWithValue }) => {
        const response = await axios.put(`/user/subcontractor-reject-from-operation/${userID}`);
        return response.data;
    }
  ); 
