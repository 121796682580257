import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const putDirectApprove = createAsyncThunk(
  "directApprove/putDirectApprove",
  async ({ vehicleID, routeID }, { rejectWithValue }) => {
    const response = await axios.put(
      `/route/approve-and-assigned-vehicle/${routeID}`,
      { vehicleID: vehicleID }
    );
    return response.data;
  }
);
