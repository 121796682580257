import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteByDate = createAsyncThunk(
  'route/getRouteListByDate',
  async ({ timestamp }, { rejectWithValue }) => {
      const refresh = JSON.parse(sessionStorage.getItem('refresh'));
      const response = await axios.post(`/route/route-list-by-date?timestamp=${timestamp}`, {
        districtIDs: refresh.data.districts,
      });
      return response.data;
  }
);