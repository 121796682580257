import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getClientManagerList = createAsyncThunk(
    'getClientManagerList',
    async (_, { rejectWithValue }) => {
      // try {
        const response = await axios.get(`/user?roles=clientManagerRole`);
        return response.data;
      // } catch (err) {
      //   return rejectWithValue(err.message);
      // }
    }
  );