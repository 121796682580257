import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin-right: 8px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.88);
  border-color: #d9d9d9 !important;,
  box-shadow: none;
`;

function AppButton({ children, isSubmitting, style, ...props }){

  return (
    <StyledButton 
      type="primary" 
      htmlType="submit" 
      className="login-form-button" 
      disabled={isSubmitting}
      style={style} 
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default AppButton;