import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteOrder = createAsyncThunk(
  'order/deleteOrder',
  async (selectedOrderID, { rejectWithValue }) => {
    // try {
      const response = await axios.delete(`/order/${selectedOrderID}`);
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);